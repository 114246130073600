"use client"
import * as Sentry from "@sentry/nextjs"
import { useEffect } from "react"
import { SlimLayout } from "./components/SlimLayout"

// https://nextjs.org/docs/app/building-your-application/routing/error-handling

export default function GlobalError(props: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    console.error(props.error)
    Sentry.captureException(props.error)
  }, [props.error])

  return (
    <html>
      <body>
        <SlimLayout>
          <h1>
            {props.error.name || "Error"} (#{props.error.digest})
          </h1>
          <p>{String(props.error.cause)}</p>
          <p>{String(props.error.message)}</p>
          <pre>{String(props.error.stack)}</pre>
          <button onClick={() => props.reset()}>Try again</button>
        </SlimLayout>
      </body>
    </html>
  )
}
